import Portal from '../Portal'
import { NavBottom } from './NavBottom'
import { NavMain } from './NavMain'
import { NavTop } from './NavTop'

const TopNav = () => {
  return (
    <>
      <Portal targetId={'nav-top-portal'}>
        <NavTop />
      </Portal>
      <Portal targetId={'nav-main-portal'}>
        <NavMain />
      </Portal>
      <Portal targetId={'nav-bottom-portal'}>
        <NavBottom />
      </Portal>
    </>
  )
}

export default TopNav
