import { createContext } from 'react'

export type ItemVariant = 'sm' | 'md' | 'lg' | 'xl' | 'xxl'

export const ItemVariantContext = createContext<ItemVariant>('md')

export interface ComponentVariant {
  variant: 'sm' | 'md' | 'lg' | 'xl' | 'xxl'
  size: 'tall' | 'wide' | 'square'
}

export const ComponentVariantContext = createContext<ComponentVariant>({ variant: 'lg', size: 'wide' })
