import {
  LeagueCard,
  MatchupCard,
  MetaEntitlementType,
  NewsCard,
  ScoreCard,
  VideoCard,
} from '@grandstand/presentation-models'
import { ComponentType } from '@grandstand/presentation-models/dist/types/components/_abstract'
export type EntitlementMeta = {
  local_network_ids: string[]
  entitlement_type: MetaEntitlementType | undefined
}

export type CardMeta =
  | {
      local_network_ids?: string[]
      entitlement_type?: MetaEntitlementType
    }
  | undefined
// TODO(tgrow): move this to card utils.
export const isLeagueCard = (card: any): card is LeagueCard => card.type === ComponentType.LeagueCard
export const isMatchupCard = (card: any): card is MatchupCard => card.type === ComponentType.MatchupCard
export const isVideoCard = (card: any): card is VideoCard => card.type === ComponentType.VideoCard
export const isScoreCard = (card: any): card is ScoreCard => card.type === ComponentType.ScoreCard
export const isNewsCard = (card: any): card is NewsCard => card.type === ComponentType.NewsCard

export const getEntitlementMeta = (card: any): EntitlementMeta => {
  const meta: EntitlementMeta = {
    local_network_ids: [],
    entitlement_type: undefined,
  }
  if (card.meta) {
    meta.local_network_ids = card.meta.local_network_ids ?? []
    meta.entitlement_type = card.meta.entitlement_type ?? undefined
  }
  return meta
}
