import { GrandstandButton } from '@/components/Buttons'
import { BaseContainer } from '@/components/containers/BaseContainer'
import { breakpoints } from '@/styles/breakpoints'
import { ButtonLabel, HeadingLabel, SubheadingLabel } from '@/styles/fonts'
import useUserError from '@grandstand-web/bally-web-shared/src/hooks/useUserError'
import { ErrorDetails } from '@grandstand-web/bally-web-shared/src/type/Error'
import Image from 'next/image'
import { MouseEventHandler } from 'react'
import styled, { css } from 'styled-components'

const Page = styled.div`
  /* min-height: 100vh; */
`

const BodyContainer = styled(BaseContainer)`
  ${breakpoints.down('tablet')} {
    padding-left: 0;
    padding-right: 0;
  }
`

const WithBackgroundStyles = styled.div`
  width: 100%;
  position: relative;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: var(--surface);
`

const ImageGradient = styled.div`
  width: 100%;
  height: 33%;
  background: linear-gradient(0deg, var(--canvas), transparent);
  position: absolute;
  bottom: 0;
`

const BackgroundImage = styled(Image)`
  width: 100%;
  height: 100%;
  margin: 0;
  display: block;
  object-fit: cover;
  object-position: top;
`
const Foreground = styled(BaseContainer)`
  position: relative;
  z-index: 2;
  width: 100%;
  aspect-ratio: 3/4;

  ${breakpoints.up('tablet')} {
    aspect-ratio: 1/1;
  }
  ${breakpoints.up('desktop')} {
    aspect-ratio: 20/9;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  gap: 20px 0;
`

const sectionsStyle = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px 0;
`

const labelStyle = css`
  color: var(--white);
  width: 100%;
  text-align: center;
`

const Sections = styled.div`
  ${sectionsStyle}
  margin: 0 auto;
  padding: 28px;
  max-width: 640px;
  gap: 56px 0;
`

const TitleSection = styled.div`
  ${labelStyle}
`

const ContentSection = styled.div`
  ${sectionsStyle}
  max-width: 480px;
  gap: 32px 0;
`

const Labels = styled.div`
  ${sectionsStyle}
  ${labelStyle}
  gap: 8px 0;
`

const Actions = styled.div`
  width: 100%;
`

const BackButton = styled(GrandstandButton)`
  width: 100%;
  text-transform: uppercase;
`

export type ErrorLabels = {
  title: string
  subtitle: string
  body: string
}

type ErrorButtons = {
  label: string
  onButtonClick: MouseEventHandler<HTMLButtonElement>
}

type ErrorPageViewProps = {
  error: ErrorDetails
}

// TODO: Check seasonal pause image after update

export const ErrorPageView = ({ error }: ErrorPageViewProps) => {
  const userError = useUserError({ scope: 'page', rawError: error })
  if (!userError) return <></>
  let backgroundSrc = error.code === 'out_of_market' ? '/lower-background-seasonal-pause.png' : '/technical-error.png'

  return (
    <Page>
      <BodyContainer padded={true}>
        <WithBackgroundStyles>
          <Background>
            <BackgroundImage src={backgroundSrc} alt="" aria-hidden={true} fill />
            <ImageGradient />
          </Background>

          <Foreground padded={true}>
            <Sections>
              <TitleSection>
                <HeadingLabel fontSize="8" tabletFontSize="6" mobileFontSize="4">
                  {userError.title}
                </HeadingLabel>
              </TitleSection>
              <ContentSection>
                <Labels>
                  {userError.subtitle && (
                    <HeadingLabel fontSize="4" tabletFontSize="3" mobileFontSize="2">
                      {userError.subtitle}
                    </HeadingLabel>
                  )}
                  <SubheadingLabel
                    fontSize="1"
                    tabletFontSize="1"
                    mobileFontSize="0"
                    dangerouslySetInnerHTML={{ __html: userError.message }}
                  />
                </Labels>
                {userError.buttons.map((button, i) => (
                  <Actions key={button.title}>
                    <BackButton
                      type="button"
                      buttonSize="md"
                      buttonStyle={i === 0 ? 'on-image' : 'alt'}
                      loading={false}
                      onClick={button.action}
                    >
                      <ButtonLabel fontSize="md">{button.title}</ButtonLabel>
                    </BackButton>
                  </Actions>
                ))}
              </ContentSection>
            </Sections>
          </Foreground>
        </WithBackgroundStyles>
      </BodyContainer>
    </Page>
  )
}
