import { Label } from '@grandstand/presentation-models'

export const textForLabel = (label: Label | undefined) => {
  if (label === undefined) {
    return ''
  }
  const labelType = label.type
  switch (labelType) {
    case 'text':
      return label.markdown ?? label.value
    case 'short_date':
      const dayOptions: {} = { month: 'short', day: 'numeric' }
      const date = new Date(label.value)
      const [month, , day] = new Intl.DateTimeFormat('en-US', dayOptions).formatToParts(date)
      return `${month.value} ${day.value}`
    case 'time':
      const timeDate = new Date(label.value)
      const dtf = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' })
      return dtf.format(timeDate)
    case 'datetime':
      const dateTime = new Date(label.value)
      const timeformatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric' })
      const [dtmonth, , dtday] = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).formatToParts(
        dateTime
      )
      return `${dtmonth.value} ${dtday.value}, ${timeformatter.format(dateTime)}`
    case 'duration':
      const textForDurationLabel = (): string => {
        const duration = Math.floor(label.value)
        if (typeof duration !== 'number') {
          return '00:00'
        }
        const hours = Math.max(Math.floor(duration / 3600), 0)
        const minutes = Math.max(Math.floor((duration % 3600) / 60), 0)
        const seconds = Math.max(Math.floor(duration % 60), 0)

        const HH = `${hours}`.padStart(Math.max(2, `${hours}`.length), '0')
        const MM = `${minutes}`.padStart(2, '0')
        const SS = `${seconds}`.padStart(2, '0')
        return hours > 0 ? `${HH}:${MM}:${SS}` : `${MM}:${SS}`
      }
      return textForDurationLabel()
    case 'datetime_range':
      const startTime = new Intl.DateTimeFormat('en', {
        day: '2-digit',
        month: 'short',
        hour: 'numeric',
        minute: '2-digit',
      })
      const endTime = new Intl.DateTimeFormat('en', {
        timeStyle: 'short',
      })
      const start = startTime.format(new Date(label.start)).replace(',', '')
      const end = endTime.format(new Date(label.end)).replace(',', '')
      const textForDateTimeRangeLabel = (): string => {
        return `${start} - ${end}`
      }
      return textForDateTimeRangeLabel()

    default:
      console.warn(`Got unknown label type: ${labelType}`)
      return 'xxx'
  }
}

function getDateToFormatParts(date: Date) {
  const arr = new Intl.DateTimeFormat('en-US', {
    day: '2-digit',
    dayPeriod: 'short',
    hour: '2-digit',
    minute: '2-digit',
    month: 'short',
    second: '2-digit',
    weekday: 'short',
    year: 'numeric',
  }).formatToParts(date)
  const getPartValue = (partType: Intl.DateTimeFormatPart['type']): string => {
    const index = arr.findIndex((part) => part.type === partType)
    if (index === -1) {
      return ''
    }
    return arr[index].value
  }
  return {
    day: getPartValue('day'),
    dayPeriod: getPartValue('dayPeriod'),
    era: getPartValue('era'),
    hour: getPartValue('hour'),
    literal: getPartValue('literal'),
    minute: getPartValue('minute'),
    month: getPartValue('month'),
    second: getPartValue('second'),
    timeZoneName: getPartValue('timeZoneName'),
    weekday: getPartValue('weekday'),
    year: getPartValue('year'),
  }
}
