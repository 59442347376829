import { ComponentVariant, ComponentVariantContext } from '@grandstand-web/bally-web-shared/src/context/VariantContexts'
import { IconCard } from '@grandstand/presentation-models'
import Image from 'next/image'
import Link from 'next/link'
import { useContext } from 'react'
import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import { srcForImage } from './Image'
import { textForLabel } from './Label'
const Action = styled(Link)`
  display: block;
  cursor: pointer;
`
type ItemSizeKey = 'oneThird' | 'oneQuarter' | 'oneHalf' | 'twoThirds' | 'eightyThree' | 'full'
type ItemGridSize = {
  width: string
  gaps: number
  items: number
}
const isVariantLg = (variant: ComponentVariant['variant']): boolean => ['lg', 'xl', 'xxl'].includes(variant)
const getCardWrapperVariables = (props: ComponentVariant): FlattenSimpleInterpolation => {
  const isLg = isVariantLg(props.variant)
  const sizes: {
    [key in ItemSizeKey]: ItemGridSize
  } = {
    oneThird: {
      width: '33.3333%',
      gaps: 2,
      items: 3,
    },
    oneQuarter: {
      width: '25%',
      gaps: 3,
      items: 4,
    },
    oneHalf: {
      width: '50%',
      gaps: 1,
      items: 2,
    },
    twoThirds: {
      width: '66.6667%',
      gaps: 0,
      items: 1,
    },
    eightyThree: {
      width: '83.5%',
      gaps: 0,
      items: 1,
    },
    full: {
      width: '100%',
      gaps: 0,
      items: 1,
    },
  }
  const lgSize = isLg ? sizes.oneThird : sizes.oneQuarter
  const mdSize = isLg ? sizes.oneHalf : sizes.oneThird
  const smSize = isLg ? sizes.twoThirds : sizes.oneHalf
  const xsSize = isLg ? sizes.eightyThree : sizes.twoThirds
  const getGapDiff = (size: ItemGridSize) => `var(--row-gap, 24px) / ${size.items} * ${size.gaps}`
  return css`
    --card-wrapper-xs-width: calc(${xsSize.width} - ${getGapDiff(xsSize)});
    --card-wrapper-sm-width: calc(${smSize.width} - ${getGapDiff(smSize)});
    --card-wrapper-md-width: calc(${mdSize.width} - ${getGapDiff(mdSize)});
    --card-wrapper-lg-width: calc(${lgSize.width} - ${getGapDiff(lgSize)});
    --card-wrapper-xl-width: var(--card-wrapper-lg-width);
  `
}
const CardWrapper = styled.div<ComponentVariant>`
  ${getCardWrapperVariables}
  display: block;
  width: var(--card-wrapper-md-width);
  max-width: var(--card-wrapper-md-width);
`
const Content = styled.div`
  --image-size: 156px;
  display: flex;
  grid-template-columns: minmax(var(--image-size), var(--image-size)) minmax(0, 100%);
  gap: 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const ThumbnailColumn = styled.div`
  position: relative;
  width: var(--image-size);
  height: var(--image-size);
`
const Thumbnail = styled(Image)`
  display: block;
  object-fit: cover;
  border-radius: 123.88px;
`
const Labels = styled.div`
  --gap: 4px 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  font-size: 20px;
  font-weight: 350;
  line-height: 24px;
  gap: var(--gap);
  color: var(--on-background);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`
export const IconCardView = ({ component }: { component: IconCard }) => {
  const {
    id,
    content: { image, title_label, slug },
  } = component
  const { variant, size } = useContext(ComponentVariantContext)
  return (
    <CardWrapper size={size} variant={variant} id={id}>
      <Action href={`/content/${slug}`}>
        <Content>
          <ThumbnailColumn>
            <Thumbnail src={srcForImage(image)} fill={true} alt={`${title_label} Icon`} />
          </ThumbnailColumn>
          <Labels>{textForLabel(title_label)}</Labels>
        </Content>
      </Action>
    </CardWrapper>
  )
}
