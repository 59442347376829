import { Tab } from '@/components/teams'
import { useColorScheme } from '@/hooks/useColorScheme'
import { HeadingLabel, SubheadingLabel } from '@/styles/fonts'
import NextImage from 'next/image'
import styled, { css } from 'styled-components'
import { useTeamHubPreview } from './TeamHubPreviewContext'

const Wrapper = styled.div`
  width: 100%;
`

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`

const Labels = styled.div`
  display: none;
  min-height: 88px;
  padding: 8px 0;
  @media (min-width: 1280px) {
    display: flex;
    align-items: center;
  }
`
const TitleLabel = styled(SubheadingLabel)`
  color: var(--on-background);
`

const teamLabelStyle = css`
  white-space: nowrap;
  text-overflow: ellipsis;
`
const TeamLabelCurrent = styled(HeadingLabel)`
  ${teamLabelStyle}
`
const TeamLabelStatic = styled(SubheadingLabel)`
  ${teamLabelStyle}
`

const List = styled.ul`
  --item-gap: 32px;
  &,
  & > * {
    list-style: none;
  }
  display: flex;
  align-items: center;
  flex-wrap: nowrap;

  padding: 0 0 20px;
  margin: 0 0 -20px;
  width: 100%;
  overflow-x: auto;
  li {
    button {
      padding: 0 8px;
    }
    &:first-child {
      button {
        padding-left: 20px;
      }
    }
    &:last-child {
      button {
        padding-right: 20px;
      }
    }
  }
  @media (min-width: 1280px) {
    flex-direction: column;
    flex-wrap: nowrap;
    overflow: visible;
    max-width: 320px;
    padding: 0;
    margin: 0;

    li {
      &,
      &:first-child,
      &:last-child {
        button {
          padding: calc(var(--item-gap) * 0.5) 0;
        }
      }
    }
  }
`

const Item = styled.li<{ isActive?: boolean }>`
  list-style: none;
  padding: 0;
  background-color: transparent;
  align-items: center;
  @media (min-width: 1280px) {
    width: 100%;
  }
`

const LogoCol = styled.div`
  --logo-size: 24px;
  position: relative;
  width: var(--logo-size);
  height: var(--logo-size);
`
const Logo = styled(NextImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
const ItemButton = styled.button<{ isActive?: boolean }>`
  --button-font-weight: 350;
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  gap: 4px;
  background-color: transparent;
  border: none;
  box-shadow: none;
  color: var(--on-background);
  text-align: left;
  cursor: pointer;
  @media (min-width: 1280px) {
    width: 100%;
    padding: 16px 16px 16px 0;
    gap: 8px;
  }
`
const TabItem = ({ tab }: { tab: Tab }) => {
  const { isDark } = useColorScheme()
  const { activeTab, setActiveTab } = useTeamHubPreview()
  const isActive = activeTab && activeTab.team.slug === tab.team.slug
  const handleClick = () => {
    if (!isActive) {
      setActiveTab(tab)
    }
  }

  const imageSrc = isDark ? tab.team.dark_image : tab.team.light_image

  return (
    <Item isActive={isActive} id={tab.id}>
      <ItemButton isActive={isActive} onClick={handleClick}>
        <LogoCol>
          <Logo src={imageSrc} alt="" fill={true} />
        </LogoCol>
        <div>
          {isActive ? (
            <TeamLabelCurrent fontSize="2" mobileFontSize="1" tabletFontSize="1">
              {tab.team.short_name}
            </TeamLabelCurrent>
          ) : (
            <TeamLabelStatic fontSize="2" mobileFontSize="1" tabletFontSize="1">
              {tab.team.short_name}
            </TeamLabelStatic>
          )}
        </div>
      </ItemButton>
    </Item>
  )
}

export const TeamHubPreviewNav = () => {
  const { tabs } = useTeamHubPreview()

  return (
    <Wrapper>
      <Sections>
        <Labels>
          <TitleLabel fontSize="2">Your Local Teams</TitleLabel>
        </Labels>
        <div>
          <List>
            {tabs.map((tab) => (
              <TabItem key={tab.id} tab={tab} />
            ))}
          </List>
        </div>
      </Sections>
    </Wrapper>
  )
}
