import { breakpoints } from '@/styles/breakpoints'

import styled, { css } from 'styled-components'

const containerPaddingStyles = css<{ padded?: boolean; fluid?: boolean }>`
  padding: var(--container-spacer) var(--container-spacer);

  ${breakpoints.down('tablet')} {
    padding: var(--tablet-container-spacer) var(--tablet-container-spacer);
  }

  ${breakpoints.down('mobile')} {
    padding: var(--mobile-container-spacer) var(--mobile-container-spacer);
  }
`
const containerStyles = css<{ padded?: boolean; fluid?: boolean }>`
  display: block;
  width: 100%;
  max-width: ${(props) => (props?.fluid ? '100%' : 'var(--container-max-width)')};
  margin-left: auto;
  margin-right: auto;
  ${(props) => (props?.padded ? containerPaddingStyles : css``)}
`

export const BaseContainer = styled.div<{ padded?: boolean; fluid?: boolean }>`
  ${containerStyles}
`
