import { PropsWithChildren, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

const Portal = ({ children, targetId }: PropsWithChildren<{ targetId: string }>) => {
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])
  return mounted && document.getElementById(targetId)
    ? createPortal(children, document.getElementById(targetId)!)
    : null
}

export default Portal
