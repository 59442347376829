import { NavigateAction } from '@grandstand/presentation-models'

export function getStaticPageUrl(action: NavigateAction, videoId: string, neighboringIds = ''): string {
  if (!videoId) {
    throw new Error('Video ID is required.')
  }

  let staticPageUrl: string

  const { page_type } = action

  if (page_type === 'game-details-page') {
    staticPageUrl = `/${page_type.split('-page')[0]}/${videoId}`
  } else {
    staticPageUrl = `/${page_type.split('-page')[0]}/${videoId}?neighboringIds=${neighboringIds}`
  }

  return staticPageUrl
}
