import { Logger } from '@grandstand-web/bally-web-shared/src/utils/logger'
import {
  BaseComponent,
  BaseComponentWithItems,
  ComponentType,
  ContentItemRail,
  DehydratedComponent,
  Grid,
  IconCard,
  MatchupCard,
  NewsCard,
  Rail,
  Ref,
  VideoCard,
  WatchPage,
  WatchVideoRail,
} from '@grandstand/presentation-models'
import { TeamHubPreview } from '../teams'
import { GridView } from './Grid'
import { IconCardView } from './IconCard'
import { MatchupCardView } from './MatchupCard'
import { NewsCardView } from './NewsCard'
import { RailView, RailViewType } from './Rail'
import { TeamHubPreviewView } from './TeamHubPreview'
import { VideoCardView } from './VideoCard'
import { WatchPageView } from './WatchPage'
import { WatchVideoRailView } from './WatchVideoRail'

type HydratedComponentTypes<Type extends ComponentType, Content> =
  | BaseComponent<Type, Content>
  | BaseComponentWithItems<Type, Content>

export const ComponentRenderer = <
  Type extends ComponentType,
  Content,
  T extends HydratedComponentTypes<Type, Content>
>({
  component,
}: {
  component: Ref<T> | T
}) => {
  const isDehydrated = (component: Ref<T> & { content?: Content }): component is DehydratedComponent =>
    component.content === undefined

  switch (component.type) {
    case ComponentType.WatchPage:
    case ComponentType.WebWatchPage:
      return <WatchPageView component={component as unknown as WatchPage}></WatchPageView>
    case ComponentType.Rail:
      const rail = component as unknown as Rail
      const railKey = rail.items.map((item) => item.id).join('-')
      return <RailView key={railKey} component={component as unknown as Rail}></RailView>
    case ComponentType.WatchVideoRail:
      const watchVideoRail = component as unknown as WatchVideoRail
      const watchVideoRailKey = [
        watchVideoRail.content.default_id,
        watchVideoRail.content.streams.map((stream) => stream.id),
      ].join('-')
      return (
        <WatchVideoRailView
          key={watchVideoRailKey}
          component={component as unknown as WatchVideoRail}
        ></WatchVideoRailView>
      )
    case ComponentType.VideoCard:
      const videoCard = component as unknown as VideoCard
      const videoCardKey = videoCard.id
      return <VideoCardView key={videoCardKey} component={component as unknown as VideoCard}></VideoCardView>
    case ComponentType.MatchupCard:
      const matchupCard = component as unknown as MatchupCard
      const matchupCardKey = matchupCard.id
      return <MatchupCardView key={matchupCardKey} component={component as unknown as MatchupCard}></MatchupCardView>
    case ComponentType.NewsCard:
      return <NewsCardView component={component as unknown as NewsCard}></NewsCardView>
    case ComponentType.IconCard:
      return <IconCardView component={component as unknown as IconCard}></IconCardView>
    case ComponentType.Grid:
      return <GridView component={component as unknown as Grid}></GridView>
    case ComponentType.TeamHubPreview:
      return <TeamHubPreviewView component={component as unknown as TeamHubPreview}></TeamHubPreviewView>
    case ComponentType.ContentItemRail:
      const iconCard = component as unknown as IconCard
      const iconCardKey = iconCard.id
      return (
        <RailView
          key={iconCardKey}
          component={component as unknown as ContentItemRail}
          railType={RailViewType.ContentItem}
        ></RailView>
      )
    case undefined:
      return null
    default:
      Logger.of('connected-web.ComponentRenderer').warn(`NO COMPONENT REGISTERED FOR TYPE ${component.type}`, component)
      return null
  }
}
