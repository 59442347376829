import { UserServiceContext } from '@grandstand-web/bally-web-shared/src/services/user/UserService'
import { EntitlementMeta, TEntitlementReason, getEntitlementInfo } from '@grandstand-web/entitlements'
import { AuthProfileOutput, MetaEntitlementType } from '@grandstand/presentation-models'
import { useContext, useEffect, useState } from 'react'

export enum UpsellMode {
  entitled = 'entitled',
  both = 'both',
  mvpd = 'mvpd',
}

export type UpsellState = {
  isEntitled: boolean | undefined
  mode: UpsellMode
  reason: TEntitlementReason
  contentUrl: string
  meta: EntitlementMeta | undefined
}

export type UpsellStore = {
  upsell: UpsellState
}
export type UpsellProps = {
  contentUrl: string
  meta: EntitlementMeta | undefined
}
export const useUpsellLogic = ({ contentUrl = '/', meta }: UpsellProps) => {
  const { isDTC, isMVPD, isLoggedIn, currentUser } = useContext(UserServiceContext)
  const [upsell, setUpsell] = useState<UpsellState>({
    contentUrl,
    meta,
    isEntitled: true,
    reason: '',
    mode: UpsellMode.entitled,
  })
  useEffect(() => {
    const next = getUpsellState({ contentUrl, meta, currentUser })
    setUpsell(next)
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentUser, meta?.local_network_ids, meta?.entitlement_type, isLoggedIn, isDTC, isMVPD, meta])

  return {
    upsell,
  }
}
type GetUpsellStateProps = {
  contentUrl: string
  meta: EntitlementMeta | undefined
  // userService: UserService
  currentUser?: AuthProfileOutput
}
export const getUpsellState = ({ contentUrl, meta, currentUser }: GetUpsellStateProps): UpsellState => {
  // convert the useEffect above into a functon that returns UpsellState
  const getState = (): UpsellState => {
    const contentType: MetaEntitlementType | undefined = meta?.entitlement_type
    const mode = contentType === 'mvpd' ? UpsellMode.mvpd : UpsellMode.both
    const createState = (isEntitled: boolean, reason: TEntitlementReason): UpsellState => {
      const next: UpsellState = {
        meta: meta,
        contentUrl: contentUrl,
        isEntitled,
        mode,
        reason,
      }
      return next
    }

    if (currentUser === undefined) {
      return createState(false, '')
    }

    const entitlementInfo = getEntitlementInfo(
      [...currentUser.profile.entitlements, ...currentUser.profile.paused_entitlements],
      meta
    )

    return createState(entitlementInfo.entitled, entitlementInfo.reason)
  }

  const upsellState = getState()
  return upsellState
}
