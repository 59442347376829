import { GrandstandButton } from '@/components/Buttons'
import { BaseContainer } from '@/components/containers/BaseContainer'
import { ComponentRenderer } from '@/components/grandstand/ComponentRenderer'
import { TeamHubHeader } from '@/components/teams'
import { useColorScheme } from '@/hooks/useColorScheme'
import { breakpoints } from '@/styles/breakpoints'
import { HeadingLabel, SubheadingLabel } from '@/styles/fonts'
import NextImage from 'next/image'
import Link from 'next/link'
import styled, { css } from 'styled-components'
import { useTeamHubPreview } from './TeamHubPreviewContext'

const Wrapper = styled.div`
  width: auto;
  padding: 0;

  ${breakpoints.up('lg')} {
    padding: 0;
  }
`

const Background = styled(NextImage)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
  z-index: 0;
  opacity: 0.025;
  // make black and white
  filter: grayscale(100%);
`

const Foreground = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 48px 0;
  width: 100%;
  z-index: 1;
`
const Container = styled(BaseContainer)`
  padding-top: 0;
  padding-bottom: 0;
  ${breakpoints.up('lg')} {
    padding-left: 0;
    padding-right: 0;
  }
`
const Header = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`
const Sections = styled.div`
  --gx: var(--mobile-container-spacer);
  --gy: var(--mobile-container-spacer);
  display: flex;
  flex-direction: column;
  width: 100%;
  ${breakpoints.up('tablet')} {
    --gx: var(--tablet-container-spacer);
  }
  ${breakpoints.up('desktop')} {
    /*  */
  }
`
const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0 0;
  padding: 0 0 24px;
`

const SectionHeadeer = styled(Container)``
const SectionTitle = styled(HeadingLabel)`
  display: block;
  width: 100%;
  color: var(--on-background);
  padding: 0 0 16px;
`

const SectionCards = styled.div`
  max-width: 100%;
  width: 100%;
  padding: 0 0 24px 0;
  overflow-x: auto;
  ${breakpoints.up('md')} {
    overflow: hidden;
  }
`
const VideoCards = styled.div`
  display: grid;
  padding: 0 var(--gx);
  gap: var(--gy) var(--gx);
  grid-template-columns: repeat(3, 1fr);
  width: 262.5%; // each card is 87.5% wide
  margin-inline: auto;
  ${breakpoints.up('sm')} {
    width: 200%;
  }
  ${breakpoints.up('md')} {
    width: 100%;
  }
  ${breakpoints.up('lg')} {
    width: 100%;
    padding: 0;
  }
`
const NewsCards = styled(VideoCards)`
  grid-template-columns: 1fr;
  gap: 16px 0;
  width: 100%;
  padding: 0 var(--mobile-container-spacer);
  ${breakpoints.up('sm')} {
    padding: 0 var(--tablet-container-spacer);
  }
  ${breakpoints.up('md')} {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 var(--tablet-container-spacer);
    ${SubheadingLabel} {
      padding-right: 16px;
    }
  }
  ${breakpoints.up('lg')} {
    padding: 0;
    ${SubheadingLabel} {
      padding-right: 16px;
    }
  }
  ${breakpoints.up('xl')} {
    padding: 0;
    ${SubheadingLabel} {
      padding-right: 24px;
    }
  }
`

const NewsCardItem = styled.div`
  overflow: hidden;
`

const VideoCardItem = styled.div`
  min-height: 100%;
  overflow: hidden;
  & > * {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
    min-height: 100% !important;
    padding: 0 !important;
    overflow: hidden !important;
  }
`

const moreStyles = css`
  display: block;
  width: 100%;
  ${breakpoints.up('md')} {
    width: auto;
  }
`
const MoreWrapper = styled.div`
  ${moreStyles}
  padding: 32px 0 0;
  ${breakpoints.up('md')} {
    padding: 0;
    width: auto;
  }
`
const MoreLink = styled(Link)`
  ${moreStyles}
`
const MoreButton = styled(GrandstandButton)`
  ${moreStyles}
`

export const TeamHubPreviewMain = () => {
  const { activeTab } = useTeamHubPreview()
  const { isDark } = useColorScheme()
  if (!activeTab) return null
  const { id, team, newsCards, videoCards } = activeTab
  const imageSrc = isDark ? team.dark_image : team.light_image
  return (
    <Wrapper>
      <Background src={imageSrc} alt="" fill />
      <Foreground>
        <Header padded={true}>
          <TeamHubHeader team={team} />
          <MoreWrapper>
            <MoreLink href={`/teams/${team.slug}`}>
              <MoreButton buttonSize="sm" buttonStyle="alt">
                View Team Hub
              </MoreButton>
            </MoreLink>
          </MoreWrapper>
        </Header>

        <Sections>
          {videoCards && (
            <Section>
              <SectionHeadeer padded={true}>
                <SectionTitle fontSize="2">Videos</SectionTitle>
              </SectionHeadeer>
              <SectionCards>
                <VideoCards>
                  {videoCards.map((card) => {
                    return (
                      <VideoCardItem key={`${id}-video-card-${card.id}`}>
                        <ComponentRenderer component={card} />
                      </VideoCardItem>
                    )
                  })}
                </VideoCards>
              </SectionCards>
            </Section>
          )}
          {newsCards && (
            <Section>
              <SectionHeadeer padded={true}>
                <SectionTitle fontSize="2">Articles</SectionTitle>
              </SectionHeadeer>
              <SectionCards>
                <NewsCards>
                  {newsCards.map((card) => {
                    return (
                      <NewsCardItem key={`${id}-news-card-${card.id}`}>
                        <ComponentRenderer component={card} />
                      </NewsCardItem>
                    )
                  })}
                </NewsCards>
              </SectionCards>
            </Section>
          )}
        </Sections>
      </Foreground>
    </Wrapper>
  )
}
