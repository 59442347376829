import { useUpsell } from '@/hooks/useUpsell'
import { ComponentVariant, ComponentVariantContext } from '@grandstand-web/bally-web-shared/src/context/VariantContexts'
import { getStaticPageUrl } from '@grandstand-web/bally-web-shared/src/utils/staticPageUtils'
import { MatchupCard, NavigateAction, VideoStatus } from '@grandstand/presentation-models'
import NextImage from 'next/image'
import Link from 'next/link'
import { MouseEvent, useContext } from 'react'
import styled, { FlattenSimpleInterpolation, css } from 'styled-components'
import { srcForImage } from './Image'

type ItemGridSize = {
  width: string
  gaps: number
  items: number
}
type ItemSizeKey = 'oneThird' | 'oneQuarter' | 'oneHalf' | 'twoThirds' | 'eightyThree' | 'full'

const isVariantLg = (variant: ComponentVariant['variant']): boolean => ['lg', 'xl', 'xxl'].includes(variant)

const getImageSizesOptimizationFromVariant = (componentVariant: ComponentVariant): string | undefined => {
  const isLg = isVariantLg(componentVariant.variant)
  const xsSize = isLg ? '83.5vw' : '66vw'
  const smSize = isLg ? '66vw' : '50vw'
  const mdSize = isLg ? '50vw' : '33vw'
  const lgSize = isLg ? '33vw' : '25vw'
  const xlSize = isLg ? '33vw' : '25vw'
  const maxSize = isLg ? '540px' : '400px'
  return `(max-width: 479px) ${xsSize}, (max-width: 767px) ${smSize}, (max-width: 1279px) ${mdSize}, (max-width: 1439px) ${lgSize}, (max-width: 1632px) ${xlSize}, ${maxSize}`
}

const getCardWrapperVariables = (props: ComponentVariant): FlattenSimpleInterpolation => {
  const isLg = isVariantLg(props.variant)
  const sizes: {
    [key in ItemSizeKey]: ItemGridSize
  } = {
    oneThird: {
      width: '33.3333%',
      gaps: 2,
      items: 3,
    },
    oneQuarter: {
      width: '25%',
      gaps: 3,
      items: 4,
    },
    oneHalf: {
      width: '50%',
      gaps: 1,
      items: 2,
    },

    twoThirds: {
      width: '66.6667%',
      gaps: 0,
      items: 1,
    },
    eightyThree: {
      width: '83.5%',
      gaps: 0,
      items: 1,
    },

    full: {
      width: '100%',
      gaps: 0,
      items: 1,
    },
  }

  const lgSize = isLg ? sizes.oneThird : sizes.oneQuarter
  const mdSize = isLg ? sizes.oneHalf : sizes.oneThird
  const smSize = isLg ? sizes.twoThirds : sizes.oneHalf
  const xsSize = isLg ? sizes.eightyThree : sizes.twoThirds
  const getGapDiff = (size: ItemGridSize) => `var(--row-gap, 24px) / ${size.items} * ${size.gaps}`
  return css`
    --card-wrapper-xs-width: calc(${xsSize.width} - ${getGapDiff(xsSize)});
    --card-wrapper-sm-width: calc(${smSize.width} - ${getGapDiff(smSize)});
    --card-wrapper-md-width: calc(${mdSize.width} - ${getGapDiff(mdSize)});
    --card-wrapper-lg-width: calc(${lgSize.width} - ${getGapDiff(lgSize)});
    --card-wrapper-xl-width: var(--card-wrapper-lg-width);
  `
}
const CardWrapper = styled.div<ComponentVariant>`
  ${getCardWrapperVariables}
  display: block;
  min-width: var(--card-wrapper-xs-width);
  width: var(--card-wrapper-xs-width);
  max-width: var(--card-wrapper-xs-width);
  @media (min-width: 480px) {
    min-width: var(--card-wrapper-sm-width);
    width: var(--card-wrapper-sm-width);
    max-width: var(--card-wrapper-sm-width);
  }
  @media (min-width: 768px) {
    min-width: var(--card-wrapper-md-width);
    width: var(--card-wrapper-md-width);
    max-width: var(--card-wrapper-md-width);
  }
  @media (min-width: 1280px) {
    min-width: var(--card-wrapper-lg-width);
    width: var(--card-wrapper-lg-width);
    max-width: var(--card-wrapper-lg-width);
  }
  @media (min-width: 1440px) {
    min-width: var(--card-wrapper-xl-width);
    width: var(--card-wrapper-xl-width);
    max-width: var(--card-wrapper-xl-width);
  }
`

const CardAction = styled(Link)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const ImageWrapper = styled.div<ComponentVariant>`
  --wide-aspect-ratio: 16/9;

  position: relative;
  width: 100%;
  aspect-ratio: ${(props) => `var(--${props.size}-aspect-ratio)`};
  overflow: hidden;
  border-radius: 4px;
  background-color: #000000;
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    width: var(--tall-width-tablet);
  }
  @media only screen and (max-width: 375px) {
    width: var(--tall-width-mobile);
  }
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
`

const Status = styled.div`
  position: relative;
  display: flex;
  z-index: 2;
  padding: 12px;
`

const StatusRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 8px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.6);
  color: var(--on-overlay);
  @media only screen and (max-width: 768px) {
    padding: 4px;
    gap: 4px;
  }
`
const LiveDot = styled.div<{ pulse?: boolean }>`
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: var(--live);
  opacity: ${(props) => (!props.pulse ? '1' : '0.3')};
  box-shadow: ${(props) => (!props.pulse ? 'none' : '0 0 0 0 var(--live)')};
  @keyframes pulse {
    0% {
      opacity: 0.3;
      box-shadow: 0 0 0 0 var(--live);
    }

    50% {
      opacity: 1;
      // pulse to 6px visually using 2px box shadow
      box-shadow: 0 0 0 2px var(--live);
    }
  }

  animation: ${(props) => (!props.pulse ? 'none' : '2s ease-out infinite pulse')};
`

const StatusLabel = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  line-height: 100%;

  @media only screen and (max-width: 375px) {
    font-size: 10px;
  }
`
const statusLabelMap: { [key in VideoStatus]: 'Live' | 'Replay' | 'Upcoming' | 'On Demand' } = {
  [VideoStatus.live]: 'Live',
  [VideoStatus.replay]: 'Replay',
  [VideoStatus.upcoming]: 'Upcoming',
  [VideoStatus.vod]: 'On Demand',
}
export const MatchupCardView = ({ component }: { component: MatchupCard }) => {
  const {
    id,
    content,
    content: { image, action, video },
    meta,
  } = component

  const staticPageUrl = getStaticPageUrl(action as NavigateAction, video?.id as string)
  const {
    upsell: { contentUrl, isEntitled },
    goToEntitled,
  } = useUpsell({ contentUrl: staticPageUrl, meta })

  const { variant, size } = useContext(ComponentVariantContext)

  // image sizes optimization based on variant
  const imageSizesOptimization = getImageSizesOptimizationFromVariant({
    variant,
    size,
  })

  const handleActionOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    goToEntitled()
  }

  return (
    <CardWrapper size={size} variant={variant}>
      <CardAction href={isEntitled ? contentUrl : `#${id}`} onClick={handleActionOnClick}>
        <ImageWrapper size={size} variant={variant}>
          <NextImage src={srcForImage(image)} alt="" fill sizes={imageSizesOptimization} />
          {content.video?.status && (
            <Status>
              <StatusRow>
                {content.video.status === VideoStatus.live && <LiveDot />}
                <StatusLabel>{statusLabelMap[content.video.status]}</StatusLabel>
              </StatusRow>
            </Status>
          )}
        </ImageWrapper>
      </CardAction>
    </CardWrapper>
  )
}
