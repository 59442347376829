// IMPORTANT:
// - use sparingly: if directed by figma, create and use a new component in /components/loading/
// - this renders a loading spinner while the page is loading

import styled from 'styled-components'
import LoadingSpinner from '../LoadingSpinner'
const LoadingStyles = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 100vh; */
  height: 100%;
  max-height: 1080px;
  margin: auto;
`

const Loading = () => {
  return (
    <LoadingStyles>
      <LoadingSpinner />
    </LoadingStyles>
  )
}

export { Loading, LoadingSpinner, Loading as default }
